/* custom modal-conten bootstrap */
.modal-content {
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
  border-style: none !important;
  background-color: rgba(0, 0, 0, 0.0005) !important;
}

/* loading style */
.loader {
  width: 150px;
  height: 150px;
  position: absolute;
  margin: 220px auto;
  z-index: 1;
  /* background-color: rgba(134, 134, 134, 0.568); */
  .logo {
    width: 45%;
    position: absolute;
    left: 27.5%;
    top: 27.5%;
    img {
      width: 100%;
      z-index: 5;
    }
  }
}
.height100 {
  height: 100%;
}
.bounce1,
.bounce2,
.bounce3 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #6aaafb;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: spreadout 2.7s infinite ease-in-out;
  animation: spreadout 2.7s infinite ease-in-out;
}

.bounce2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.bounce3 {
  -webkit-animation-delay: -1.8s;
  animation-delay: -1.8s;
}

@-webkit-keyframes spreadout {
  0% {
    -webkit-transform: scale(0.3);
  }
  80% {
    -webkit-transform: scale(1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes spreadout {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
  }
}

.left-0 {
  padding-left: 0px;
  left: 0px;
}

/*   card loading */
#partI {
  /* height: 100vh; */
  min-height: 122vh;
  z-index: 9999;
  position: absolute;
  background-color: var(--bs-tertiary-color);
}

.vertical-center {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  justify-content: center; /*only if you have a small text as ,example I' otherwise for long text you don't need this line*/
}
